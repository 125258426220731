export enum AlertType {
  Success = 'success',
  Error = 'error',
  Warning = 'warn',
}

export class AlertModel {
  title = '';
  message?: string;
  details = new Array<string>();
  type = AlertType.Success;
}
