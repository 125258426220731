import { Benefit } from "./benefit.model";
import { Client } from "./client.model";

export class Member {
  id!:number;
  clientId?:number;
  clientName?:string;
  token?:string;
  gsToken?:string;
  firstName!: string;
  lastName!:string;
  email!:string;
  phone?:string;
  leadSource?:string;
  productName?: string;
  campaignCode?:string;
  billingAddress?:string;
  billingCity?:string;
  billingState?:string;
  billingZipcode?:string;
  cancelledDate?:string;
  createdAt?:string;
  updatedAt?:string;
  enrolledAt?:string;
  dateEnrolled!:string;
  benefitList?:Array<Benefit>;
  status?:string;
  client?: Client;
  reverseName?: string;
}
