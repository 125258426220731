import {
  State,
  NgxsOnInit,
  Selector,
  StateContext,
  Action,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Client } from '../models/client.model';
import { ClientsService } from '../services/clients.service';
import { ClientsActions } from './client.actions';
import { Navigate, RouterNavigation } from '@ngxs/router-plugin';
import { Member } from '../models/member.model';
import { ClientMemberReport } from '../models/client-members-report.model';

export class ClientSearchModel {
  name = '';
}

export interface ClientsStateModel {
  clients: Array<Client>;
  memberReport: ClientMemberReport;
  client: {
    model: Client;
  };
  selectedClient: Client;
  clientFilters: { model: ClientSearchModel };
}

@State<ClientsStateModel>({
  name: 'clients',
  defaults: {
    clients: new Array<Client>(),
    memberReport: new ClientMemberReport(),
    client: { model: new Client() },
    selectedClient: new Client(),
    clientFilters: { model: new ClientSearchModel() },
  },
})
@Injectable()
export class ClientsState {
  constructor(private clientsService: ClientsService, private store: Store) {}

  @Selector()
  public static getClients(state: ClientsStateModel) {
    return state.clients;
  }


  @Selector()
  public static getSelectedClient(state: ClientsStateModel) {
    return state.selectedClient;
  }

  @Selector()
  public static getClientsMembers(state: ClientsStateModel) {
    return state.selectedClient.memberDTOs;
  }

  @Selector()
  public static getClientsMembersReport(state: ClientsStateModel) {
    return state.memberReport;
  }

  @Selector()
  static getClientsFiltered({
    clientFilters: {
      model: { name },
    },
    clients,
  }: ClientsStateModel): Array<Client> {
    const results = clients;
    
    if (name.length > 0) {
      return results.filter((x) => x.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()));
    } else {
      return results;
    }
  }

  @Action(ClientsActions.GetClients)
  public getClients({ patchState }: StateContext<ClientsStateModel>) {
    return this.clientsService.getClients().pipe(
      tap((results: Client[]) =>
        patchState({
          clients: results.sort((a, b) => (a.name < b.name ? 1 : -1)),
        })
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(ClientsActions.GetSelectedClient)
  public getSelectedClient(
    { patchState, dispatch }: StateContext<ClientsStateModel>,
    action: ClientsActions.GetSelectedClient
  ) {
    return this.clientsService.getSelectedClient(action.id).pipe(
      tap((result: Client) => {
        patchState({
          selectedClient: result,
        });
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(ClientsActions.GetClientReport)
  public getClientReport(
    { patchState }: StateContext<ClientsStateModel>, action: ClientsActions.GetClientReport
  ) {
    return this.clientsService.getClientReport(action.id).pipe(
      tap((result: ClientMemberReport) => {
        patchState({
          memberReport: result,
        });
        return true;
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(ClientsActions.EditClient)
  editClient(
    { patchState }: StateContext<ClientsStateModel>,
    action: ClientsActions.GetSelectedClient
  ) {
    return this.clientsService.getSelectedClient(action.id).pipe(
      tap((result: Client) => {
        patchState({
          selectedClient: result,
          client: { model: result },
        });
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(ClientsActions.DeleteClient)
  public deleteClient(
    { dispatch }: StateContext<ClientsStateModel>,
    action: ClientsActions.DeleteClient
  ) {
    return this.clientsService.deleteClient(action.id).pipe(
      tap((result: any) => {
        dispatch(new ClientsActions.GetClients());
        return true;
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(ClientsActions.SearchClient)
  public searchClient(
    { patchState }: StateContext<ClientsStateModel>,
    action: ClientsActions.SearchClient
  ) {
    return patchState({
      clientFilters: { model: { name: action.search } },
    });
  }

  @Action(ClientsActions.ClearSearch)
  public clearSearch(
    { patchState }: StateContext<ClientsStateModel>,
  ) {
    return patchState({
      clientFilters: { model: { name: '' } },
    });
  }

  @Action(ClientsActions.UpdateClient)
  public updateClient({ getState }: StateContext<ClientsStateModel>) {
    const { client } = getState();
    return this.clientsService.updateClient(client.model).pipe(
      tap((result: any) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(ClientsActions.CreateNewClient)
  public createNewClient({ getState }: StateContext<ClientsStateModel>) {
    const { client } = getState();
    return this.clientsService.addClient(client.model).pipe(
      tap((result: any) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }
}
