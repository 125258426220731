
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ClientsActions {
  const typeName = '[Client]';

  export class GetClients {
    public static readonly type = `${typeName} Get All`;
  }

  export class GetSelectedClient {
    public static readonly type = `${typeName} Get Selected`;
    constructor(public id: number) {}
  }

  export class GetClientReport {
    public static readonly type = `${typeName} Report`;  
    constructor(public id:number){}  
  }

  export class EditClient {
    public static readonly type = `${typeName} Edit`;
    constructor(public id: number) {}
  }

  export class DeleteClient {
    public static readonly type = `${typeName} Delete`;
    constructor(public id: number) {}
  }

  export class UpdateClient {
    public static readonly type = `${typeName} Update`;
  }

  export class CreateNewClient {
    public static readonly type = `${typeName} Create New`;
  }

  export class ClearSearch {
    public static readonly type = `${typeName} Clear Search`;
  }

  export class SearchClient {
    public static readonly type= `${typeName} search`;
    constructor(public search: string) {}
  }
}
