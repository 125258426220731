import {
  State,
  NgxsOnInit,
  Selector,
  StateContext,
  Action,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Member } from '../models/member.model';
import { MembersActions } from './member.actions';
import { MembersService } from '../services/members.service';

export class MemberSearchModel {
  name = '';
}

export interface MembersStateModel {
  members: Array<Member>;
  member: {
    model: Member;
  };
  selectMember: Member;
  memberFilters: { model: MemberSearchModel };
  loggedIn: Member;
}

@State<MembersStateModel>({
  name: 'members',
  defaults: {
    members: [],
    member: { model: new Member() },
    selectMember: new Member(),
    memberFilters: { model: new MemberSearchModel() },
    loggedIn: new Member(),
  },
})
@Injectable()
export class MembersState {
  constructor(private memberService: MembersService, private store: Store) {}

  @Selector()
  public static getMembers(state: MembersStateModel) {
    return state.members;
  }

  @Selector()
  public static getLoggedInMember(state: MembersStateModel) {
    return state.loggedIn;
  }

  @Selector()
  public static getReportMembers(state: MembersStateModel) {
    return state.members;
  }

  @Selector()
  public static getSelectedMember(state: MembersStateModel) {
    return state.selectMember;
  }

  @Selector()
  public static getMemberBenefits(state: MembersStateModel) {
    return state.selectMember.benefitList;
  }

  @Selector()
  static getMembersFiltered({
    memberFilters: {
      model: { name },
    },
    members,
  }: MembersStateModel): Array<Member> {
    const results = members;

    if (name.length > 0) {
      return results.filter(
        (x) =>
          x.client?.name
            .toLocaleLowerCase()
            .includes(name.toLocaleLowerCase()) ||
          x.reverseName
            ?.toLocaleLowerCase()
            .includes(name.toLocaleLowerCase()) ||
          x.email.toLocaleLowerCase().includes(name.toLocaleLowerCase())
      );
    } else {
      return results;
    }
  }

  @Action(MembersActions.LoginMember)
  public loginMember(
    { patchState }: StateContext<MembersStateModel>,
    action: MembersActions.LoginMember
  ) {
    return patchState({
      loggedIn: action.member,
    });
  }

  @Action(MembersActions.LogoutMember)
  public logoutMember({ patchState }: StateContext<MembersStateModel>) {
    return patchState({
      loggedIn: new Member(),
    });
  }

  @Action(MembersActions.GetMembers)
  public getMembers({ patchState }: StateContext<MembersStateModel>) {
    return this.memberService.getMembers().pipe(
      tap((results: Member[]) =>
        patchState({
          members: results.sort((a, b) => (a.email < b.email ? 1 : -1)),
        })
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(MembersActions.GetSelectedMember)
  public getSelectedMember(
    { patchState }: StateContext<MembersStateModel>,
    action: MembersActions.GetSelectedMember
  ) {
    return this.memberService.getSelectedMember(action.id).pipe(
      tap((result: Member) =>
        patchState({
          selectMember: result,
        })
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(MembersActions.EditMember)
  public editMember(
    { patchState }: StateContext<MembersStateModel>,
    action: MembersActions.EditMember
  ) {
    return this.memberService.getSelectedMember(action.id).pipe(
      tap((result: Member) =>
        patchState({
          selectMember: result,
          member: { model: result },
        })
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(MembersActions.SearchMember)
  public searchClient(
    { patchState }: StateContext<MembersStateModel>,
    action: MembersActions.SearchMember
  ) {
    return patchState({
      memberFilters: { model: { name: action.search } },
    });
  }

  @Action(MembersActions.ClearSearch)
  public clearSearch({ patchState }: StateContext<MembersStateModel>) {
    return patchState({
      memberFilters: { model: { name: '' } },
    });
  }

  @Action(MembersActions.DeleteMember)
  public deleteMember(
    { dispatch }: StateContext<MembersStateModel>,
    action: MembersActions.DeleteMember
  ) {
    return this.memberService.deleteMember(action.id).pipe(
      tap((result: any) => {
        dispatch(new MembersActions.GetMembers());
        return true;
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(MembersActions.UpdateMember)
  public updateMember({ getState }: StateContext<MembersStateModel>) {
    const { member } = getState();
    return this.memberService.updateMember(member.model).pipe(
      tap((result: any) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(MembersActions.CreateNewMember)
  public createNewMember({ getState }: StateContext<MembersStateModel>) {
    const { member } = getState();
    return this.memberService.addMember(member.model).pipe(
      tap((result: any) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }
}
