import {
  State,
  NgxsOnInit,
  Selector,
  StateContext,
  Action,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { User } from '../models/user.model';
import { UsersActions } from './user.actions';
import { UsersService } from '../services/users.service';

export class UserSearchModel {
  name = '';
}

export interface UsersStateModel {
  users: Array<User>;
  user: {
    model: User;
  };
  selectedUser: User;
  userFilter: { model: UserSearchModel };
}

@State<UsersStateModel>({
  name: 'users',
  defaults: {
    users: [],
    user: { model: new User() },
    selectedUser: new User(),
    userFilter: { model: new UserSearchModel() },
  },
})
@Injectable()
export class UsersState {
  constructor(private userService: UsersService, private store: Store) {}

  @Selector()
  public static getUsers(state: UsersStateModel) {
    return state.users;
  }

  @Selector()
  public static getSelectedUser(state: UsersStateModel) {
    return state.selectedUser;
  }

  @Selector()
  static getUsersFiltered({
    userFilter: {
      model: { name },
    },
    users,
  }: UsersStateModel): Array<User> {
    const results = users;

    if (name.length > 0) {
      return results.filter((x) => x.email.toLocaleLowerCase().includes(name.toLocaleLowerCase()) || x.client?.name.toLocaleLowerCase().includes(name.toLocaleLowerCase()) );
    } else {
      return results;
    }

    return results;
  }

  @Action(UsersActions.GetUsers)
  public getUsers({ patchState }: StateContext<UsersStateModel>) {
    return this.userService.getUsers().pipe(
      tap((results: User[]) =>
        patchState({
          users: results.sort((a, b) => (a.email < b.email ? 1 : -1)),
        })
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(UsersActions.EditUser)
  public editUser(
    { patchState }: StateContext<UsersStateModel>,
    action: UsersActions.EditUser
  ) {
    return this.userService.getSelectedUser(action.id).pipe(
      tap((result: User) =>
        {

          patchState({
          selectedUser: result,
          user: { model: result },
        })}
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(UsersActions.GetSelectedUser)
  public getSelectedUser(
    { patchState }: StateContext<UsersStateModel>,
    action: UsersActions.GetSelectedUser
  ) {
    return this.userService.getSelectedUser(action.id).pipe(
      tap((result: User) =>
        patchState({
          selectedUser: result,
        })
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(UsersActions.DeleteUser)
  public deleteUser({ dispatch }: StateContext<UsersStateModel>, action: UsersActions.DeleteUser) {
    return this.userService.deleteUser(action.id).pipe(
      tap((result: any) => {
        dispatch(new UsersActions.GetUsers());
        return true;
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(UsersActions.SearchUser)
  public searchUser(
    { patchState }: StateContext<UsersStateModel>,
    action: UsersActions.SearchUser
  ) {
    return patchState({
      userFilter: { model: { name: action.search } },
    });
  }

  @Action(UsersActions.ClearSearch)
  public clearSearch(
    { patchState }: StateContext<UsersStateModel>,
  ) {
    return patchState({
      userFilter: { model: { name: '' } },
    });
  }

  @Action(UsersActions.UpdateUser)
  public updateUser({ getState }: StateContext<UsersStateModel>) {
    const { user } = getState();
    return this.userService.updateUser(user.model).pipe(
      tap((result: any) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(UsersActions.CreateNewUser)
  public createNewUser({ getState }: StateContext<UsersStateModel>) {
    const { user } = getState();
    return this.userService.addUser(user.model).pipe(
      tap((result: any) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }
}
