<section class="flex justify-center flex-col py-5">
  <div class="alert alert-success">Signed in successfully</div>
  <h1>
    Guard Street Privacy Plus
  </h1>
  <h5>Safeguarding your privacy is as easy as 1–2–3!</h5>
  <div class="flex flex-row px-10 gap-10">
    <div class="benefit">
      <div class="benefit-header">
        <img src="/assets/img/private-wifi.png" />
      </div>
      <div class="benefit-body">
        <h1 class=""><small class="text-muted">Web Activity</small></h1>
        <p>
          When you're on the web, your activity and whereabouts can be tracked.
          With Guard Street, all your web activity whether you're shopping,
          banking or just browsing is private and anonymous.
        </p>
      </div>
      <div class="benefit-footer">
        <button
          (click)="open('https://www.privatewifi.com/devices/')"
          target="_blank"
          class="download-button"
          title="Download Private Wifi"
        >
          Download
        </button>
      </div>
    </div>

    <div class="benefit">
      <div class="benefit-header">
        <img src="/assets/img/sekur-safe.svg" />
      </div>
      <div class="benefit-body">
        <h1 class=""><small class="text-muted">Email & Data</small></h1>
        <p>
          Most cybercrimes happen through email and hackers will find ways to
          access your data. Your kit gives you a secure email so you can't be
          tracked, and a Swiss Data Vault to store all your precious
          information.
        </p>
      </div>
      <div class="benefit-footer">
        <button class="download-button" title="Download Private Wifi">
          Activate
        </button>
      </div>
    </div>
    <div class="benefit">
      <div class="benefit-header">
        <img src="/assets/img/password-boss.webp" />
      </div>
      <div class="benefit-body">
        <h1 class=""><small class="text-muted">Safe Passwords</small></h1>
        <p>
          This protection tool allows you to create, store and manage your
          multiple passwords for all of the online websites that you access.
        </p>
      </div>
      <div class="benefit-footer">
        <button
          (click)="open('https://www.passwordboss.com/download/')"
          class="download-button"
          title="Download Private Wifi"
        >
          Download
        </button>
      </div>
    </div>
  </div>
</section>
