import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { SiteLayoutViewModel } from './site-layout.view-model';
import { Select, Store } from '@ngxs/store';
import { Client, ClientsActions, ClientsState, Member, MembersState } from '@guardstreet/data-access-shared';

@Component({
  selector: 'guardstreet-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrl: './site-layout.component.scss',
})
export class SiteLayoutComponent {
  siteLayout$: Observable<SiteLayoutViewModel>;
  @Select(ClientsState.getClients)
  clients$!: Observable<Array<Client>>;

  @Select(MembersState.getLoggedInMember)
  member$!: Observable<Member>;

  constructor(router: Router, private store: Store) {    
   
    this.siteLayout$ = router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => {                
        const url = new URL(e.url, location.href);        
        const model = {
          isMain: url.pathname === '/' || url.pathname==='/login',
          isUser: url.pathname ==='/benefits',
          isAdmin: url.pathname.includes('admin')      
        } as SiteLayoutViewModel;
        if(model.isAdmin){
          this.store.dispatch(new ClientsActions.GetClients());
        }
        return model;
      })
    );
  }

  showNav = (siteLayout: SiteLayoutViewModel) => siteLayout.isAdmin
}


