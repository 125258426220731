import { Member } from './member.model';

export class Benefit {
  id!: number;
  memberId?: number;
  member?: Member;
  service?: string;
  data?: string;
  activatedAt?: string;
  createdAt?: string;
  cancelledAt?: string;
  updatedAt?: string;
}
