<div fxLayoutAlign="flex justify-between">
    <div class="flex flex-col">
      <h3 class="pb-3 text-lg">{{ data.message }}</h3>
      <ul *ngIf="data.details.length > 0">
        <li *ngFor="let detail of data.details">{{ detail }}</li>
      </ul>
    </div>
    <div class="flex justify-center pt-5">
      <button mat-button (click)="snackBarRef.dismiss()">
        DISMISS
      </button>
    </div>
  </div>