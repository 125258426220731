/**
 * Created by Ashish8833 on 7/18/17.
 */
export class Signup {
  firstName?:string;
  lastName?:string;
  email?:string;
  password?:string;
  confirmPassword?:string;  
  acceptedTerms?:boolean = false;
  memberId?:string;
  birthDate?: Date;  
}
