
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ClientsState } from './states/client.state';
import { MembersState } from './states/member.state';
import { UsersState } from './states/user.state';
import { BenefitsState } from './states/benefit.state';


@NgModule({
  imports: [
    CommonModule,
    HttpClientModule,
    NgxsModule.forFeature([
      ClientsState,
      MembersState,
      UsersState,
      BenefitsState
    ]),
  ],
  exports: [],
  declarations: [],
  providers: [],
})
export class DataAccessSharedModule {}
