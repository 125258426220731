import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@guardstreet/app-config';
import { Observable } from 'rxjs';
import { Environment } from '../interfaces/environment';
import { Client } from '../models/client.model';

@Injectable({
  providedIn: 'root',
})
export class ClientsService {
  constructor(private httpClient: HttpClient, @Inject(APP_CONFIG) private appConfig: Environment) {}

  getClients(): Observable<Client[]> {
    return this.httpClient.get<Client[]>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/clients/`
    );
  }

  getSelectedClient(id: number): Observable<Client> {
    return this.httpClient.get<Client>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/clients/${id}`
    );
  }

  updateClient(client: Client): Observable<any> {
    return this.httpClient.post<any>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/clients/${client.id}`,
        client
    );
  }

  deleteClient(id: number): Observable<any> {
    return this.httpClient.delete<any>(`${this.appConfig.secureApiUrl}/api/v1/admin/clients/${id}`);
  }

  addClient(client: Client): Observable<any> {
    return this.httpClient.post<any>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/clients/`,
      client
    );
  }

  getClientReport(id: number): Observable<any> {
    return this.httpClient.get<any>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/clients/${id}/csv`
    );
  }
}
