import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@guardstreet/app-config';
import { Observable } from 'rxjs';
import { Environment } from '../interfaces/environment';
import { Member } from '../models/member.model';

@Injectable({
  providedIn: 'root'
})
export class MembersService {

  constructor(
    private httpClient: HttpClient,@Inject(APP_CONFIG) private appConfig: Environment){}

    getMembers(): Observable<Member[]> {
      return this.httpClient.get<Member[]>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/members/`
      );
    }

    getSelectedMember(id: number): Observable<Member> {
      return this.httpClient.get<Member>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/members/${id}`
      );  
    }

    updateMember(member: Member): Observable<any> {
      return this.httpClient.post<any>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/members/${member.id}`, member
      );
    }

    deleteMember(id: number): Observable<any> {
      return this.httpClient.delete<any>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/members/${id}`
      );
    }

    addMember(member: Member): Observable<any> {
      return this.httpClient.post<any>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/members/enroll`, member
      );
    }
  
}
