<form [formGroup]="searchForm" (keydown)="search($event)" ngxsForm="clients.clientFilters">
  <mat-form-field class="search-input">
    <fa-icon [icon]="faSearch" matPrefix class="search-icon"></fa-icon>
    <input
      matInput
      formControlName="name"
      type="text"
      placeholder="Search {{searchType}}"
      required
      class="no-border-search-input"
    />
    <fa-icon
      [icon]="faTimesCircle"
      (click)="clear()"
      matSuffix
      class="clear-button"
    ></fa-icon>
  </mat-form-field>
</form>
