import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { RouterModule } from '@angular/router';
import { ContainerComponent } from './container/container.component';
import { SiteFooterComponent } from './site-footer/site-footer.component';
import { SiteHeaderComponent } from './site-header/site-header.component';
import { SiteLayoutComponent } from './site-layout/site-layout.component';
import { AdminSideNavComponent } from './admin-side-nav/admin-side-nav.component';
import { SearchInputComponent } from './search-input/search-input.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatSelectModule } from '@angular/material/select';
import { AlertComponent } from './alert/alert.component';
import { BenefitsPageComponent } from './benefits-page/benefits-page.component';
@NgModule({
  declarations: [
    SiteFooterComponent,
    SiteHeaderComponent,
    SiteLayoutComponent,
    ContainerComponent,    
    AdminSideNavComponent,
    SearchInputComponent,
    AlertComponent,
    BenefitsPageComponent,
  ],
  exports: [
    SiteFooterComponent,
    SiteHeaderComponent,
    SiteLayoutComponent,
    ContainerComponent,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    SearchInputComponent,
    MatSelectModule,
    AlertComponent,
    BenefitsPageComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatFormFieldModule,
    MatCheckboxModule,
    FontAwesomeModule,
    MatSelectModule
  ],
})
export class UiSharedModule {}
