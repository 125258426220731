import {
  State,
  NgxsOnInit,
  Selector,
  StateContext,
  Action,
  Store,
} from '@ngxs/store';
import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Benefit } from '../models/benefit.model';
import { BenefitActions } from './benefit.actions';
import { BenefitsService } from '../services/benefits.service';

export class BenefitSearchModel {
  name = '';
}

export interface BenefitStateModel {
  benefits: Array<Benefit>;
  benefit: {
    model: Benefit;
  };
  selectedBenefit: Benefit;
  benefitFilter: { model: BenefitSearchModel };
}

@State<BenefitStateModel>({
  name: 'benefits',
  defaults: {
    benefits: [],
    benefit: { model: new Benefit() },
    selectedBenefit: new Benefit(),
    benefitFilter: { model: new BenefitSearchModel() },
  },
})
@Injectable()
export class BenefitsState {
  constructor(private benefitService: BenefitsService, private store: Store) {}

  @Selector()
  public static getBenefits(state: BenefitStateModel) {
    return state.benefits;
  }

  @Selector()
  public static getSelectedBenefit(state: BenefitStateModel) {
    return state.selectedBenefit;
  }

  @Selector()
  static getBenefitsFiltered({
    benefitFilter: {
      model: { name },
    },
    benefits,
  }: BenefitStateModel): Array<Benefit> {
    const results = benefits;

    if (name.length > 0) {
      return results.filter((x) => x.data?.toLocaleLowerCase().includes(name.toLocaleLowerCase()) );
    } else {
      return results;
    }

    return results;
  }

  @Action(BenefitActions.GetBenefits)
  public getBenefits({ patchState }: StateContext<BenefitStateModel>) {
    return this.benefitService.getBenefits().pipe(
      tap((results: Benefit[]) =>
        patchState({
          benefits: results,
        })
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(BenefitActions.EditBenefit)
  public editBenefit(
    { patchState }: StateContext<BenefitStateModel>,
    action: BenefitActions.EditBenefit
  ) {
    return this.benefitService.getSelectedBenefit(action.id).pipe(
      tap((result: Benefit) =>
        {

          patchState({
          selectedBenefit: result,
          benefit: { model: result },
        })}
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(BenefitActions.GetSelectedBenefit)
  public getSelectedBenefit(
    { patchState }: StateContext<BenefitStateModel>,
    action: BenefitActions.GetSelectedBenefit
  ) {
    return this.benefitService.getSelectedBenefit(action.id).pipe(
      tap((result: Benefit) =>
        patchState({
          selectedBenefit: result,
        })
      ),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(BenefitActions.DeleteBenefit)
  public deleteBenefit({ dispatch }: StateContext<BenefitStateModel>, action: BenefitActions.DeleteBenefit) {
    return this.benefitService.deleteBenefit(action.id).pipe(
      tap((result: any) => {
        dispatch(new BenefitActions.GetBenefits());
        return true;
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(BenefitActions.SearchBenefits)
  public searchBenefit(
    { patchState }: StateContext<BenefitStateModel>,
    action: BenefitActions.SearchBenefits
  ) {
    return patchState({
      benefitFilter: { model: { name: action.search } },
    });
  }

  @Action(BenefitActions.ClearSearch)
  public clearSearch(
    { patchState }: StateContext<BenefitStateModel>,
  ) {
    return patchState({
      benefitFilter: { model: { name: '' } },
    });
  }

  @Action(BenefitActions.UpdateBenefit)
  public updateBenefit({ getState }: StateContext<BenefitStateModel>) {
    const { benefit } = getState();
    return this.benefitService.updateBenefit(benefit.model).pipe(
      tap((result: any) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }

  @Action(BenefitActions.CreateNewBenefit)
  public createNewBenefit({ getState }: StateContext<BenefitStateModel>) {
    const { benefit } = getState();
    return this.benefitService.addBenefit(benefit.model).pipe(
      tap((result: any) => {
        if (result) {
          return true;
        } else {
          return false;
        }
      }),
      catchError((error: HttpErrorResponse, caught: Observable<any>) => {
        return throwError(error);
      })
    );
  }
}
