// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace UsersActions {
  const typeName = '[User]';

  export class GetUsers {
    public static readonly type = `${typeName} Get All`;    
  }

  export class GetSelectedUser {
    public static readonly type = `${typeName} Get Selected`;
    constructor(public id: number) {}
  }

  export class DeleteUser {
    public static readonly type = `${typeName} Delete`;
    constructor(public id: number) {}
  }

  export class EditUser {
    public static readonly type = `${typeName} Edit`;  
    constructor(public id: number) {}  
  }
  export class UpdateUser {
    public static readonly type = `${typeName} Update`;    
  }

  export class CreateNewUser {
    public static readonly type = `${typeName} Create New`;    
  }

  export class SearchUser {
    public static readonly type= `${typeName} search`;    
    constructor(public search: string) {}
  }

  export class ClearSearch {
    public static readonly type = `${typeName} Clear Search`;
  }
}
