import { Component, Input } from '@angular/core';
import { faSearch, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import {
  BenefitActions,
  ClientsActions,
  MembersActions,
  UsersActions,
} from '@guardstreet/data-access-shared';

@Component({
  selector: 'guardstreet-search-input',
  templateUrl: './search-input.component.html',
  styleUrl: './search-input.component.scss',
})
export class SearchInputComponent {
  faSearch = faSearch;
  faTimesCircle = faTimesCircle;
  @Input() searchType?: string;

  searchForm = new FormGroup({
    name: new FormControl(''),
  });

  constructor(private store: Store) {}

  search(event: any) {
    const search = this.searchForm.get('name')?.value
      ? this.searchForm.get('name')?.value
      : null;
    if (event.keyCode === 13 && search) {
      if (this.searchType === 'Clients') {
        this.store.dispatch(new ClientsActions.SearchClient(search));
      } else if (this.searchType === 'Members') {
        this.store.dispatch(new MembersActions.SearchMember(search));
      } else if (this.searchType === 'Users') {
        this.store.dispatch(new UsersActions.SearchUser(search));
      }
      else if (this.searchType === 'Benefits') {
        this.store.dispatch(new BenefitActions.SearchBenefits(search));
      }
    }
  }

  clear() {
    this.searchForm.get('name')?.setValue('');
    if (this.searchType === 'Clients') {
      this.store.dispatch(new ClientsActions.ClearSearch());
    } else if (this.searchType === 'Members') {
      this.store.dispatch(new MembersActions.ClearSearch());
    } else if (this.searchType === 'Users') {
      this.store.dispatch(new UsersActions.ClearSearch());
    }
  }
}
