import { Component, Inject } from '@angular/core';
import {
  MAT_SNACK_BAR_DATA,
  MatSnackBarRef,
} from '@angular/material/snack-bar';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

export enum AlertType {
  Success = 'success',
  Error = 'error',
  Warning = 'warn',
}

export class AlertModel {
  title = '';
  message?: string;
  details = new Array<string>();
  type = AlertType.Success;
}

@Component({
  selector: 'guardstreet-alert',
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  faTimes = faTimes;
  constructor(
    public snackBarRef: MatSnackBarRef<AlertComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: AlertModel
  ) {}
}
