// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace BenefitActions {
  const typeName = '[Benefit]';

  export class GetBenefits {
    public static readonly type = `${typeName} Get All`;    
  }

  export class GetSelectedBenefit {
    public static readonly type = `${typeName} Get Selected`;
    constructor(public id: number) {}
  }

  export class DeleteBenefit {
    public static readonly type = `${typeName} Delete`;
    constructor(public id: number) {}
  }

  export class EditBenefit {
    public static readonly type = `${typeName} Edit`;  
    constructor(public id: number) {}  
  }
  export class UpdateBenefit {
    public static readonly type = `${typeName} Update`;    
  }

  export class CreateNewBenefit {
    public static readonly type = `${typeName} Create New`;    
  }

  export class SearchBenefits {
    public static readonly type= `${typeName} search`;    
    constructor(public search: string) {}
  }

  export class ClearSearch {
    public static readonly type = `${typeName} Clear Search`;
  }
}
