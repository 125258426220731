import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@guardstreet/app-config';
import { Environment } from '../interfaces/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Benefit } from '../models/benefit.model';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {
  
  constructor(
  private httpClient: HttpClient, 
  @Inject(APP_CONFIG) private appConfig: Environment){}

  getBenefits(): Observable<Benefit[]> {
    return this.httpClient.get<Benefit[]>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/benefits`
    );
  }

  getSelectedBenefit(id: number): Observable<Benefit> {
    return this.httpClient.get<Benefit>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/benefits/${id}`
    );  
  }

  updateBenefit(benefit: Benefit): Observable<any> {
    return this.httpClient.post<any>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/benefits/${benefit.id}`, benefit
    );
  }

  deleteBenefit(id: number): Observable<any> {
    return this.httpClient.delete<any>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/benefits/${id}`
    );
  }

  addBenefit(benefit: Benefit): Observable<any> {
    return this.httpClient.post<any>(
      `${this.appConfig.secureApiUrl}/api/v1/admin/benefits/`, benefit
    );
  }

}
