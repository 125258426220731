import { Inject, Injectable } from '@angular/core';
import { APP_CONFIG } from '@guardstreet/app-config';
import { Environment } from '../interfaces/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user.model';

@Injectable({
  providedIn: 'root'
})
export class UsersService {

  constructor(
  private httpClient: HttpClient, @Inject(APP_CONFIG) private appConfig: Environment){}

    getUsers(): Observable<User[]> {
      return this.httpClient.get<User[]>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/users/`
      );
    }

    getSelectedUser(id: number): Observable<User> {
      return this.httpClient.get<User>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/users/${id}`
      );  
    }

    updateUser(user: User): Observable<any> {
      return this.httpClient.post<any>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/users/${user.id}`, user
      );
    }

    deleteUser(id: number): Observable<any> {
      return this.httpClient.delete<any>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/users/${id}`
      );
    }

    addUser(user: User): Observable<any> {
      return this.httpClient.post<any>(
        `${this.appConfig.secureApiUrl}/api/v1/admin/users/`, user
      );
    }
  
}
