import { Component, Input } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Client, ClientsActions } from '@guardstreet/data-access-shared';
import { Store } from '@ngxs/store';

@Component({
  selector: 'guardstreet-admin-side-nav',
  templateUrl: './admin-side-nav.component.html',
  styleUrl: './admin-side-nav.component.scss',
})
export class AdminSideNavComponent {
  @Input() isAdmin?: boolean;

  constructor(private router: Router, private store: Store) {}

  @Input() clients!: Array<Client> | null;

  clientForm = new FormGroup({
    clientId: new FormControl(0, Validators.required),
  });

  viewReport() {
    const id = this.clientForm.get('clientId')?.value
      ? this.clientForm.get('clientId')?.value
      : null;
    if (id) {
      this.store.dispatch(new ClientsActions.GetClientReport(id)).subscribe((x: boolean)=>{
        if(x){
          this.router.navigate(['/admin/report']);
        }
      });
    }
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    /*
    We clear the storage on all tabs by setting a new item, if we depend on the clear to trigger
    it can cause an infinite loop of calls accross tabs/browsers
    */
    localStorage.setItem('hor-logout', Date.now().toString());
    localStorage.removeItem('hor-logout');
    this.router.navigate(['/login/user']);
  }
}
