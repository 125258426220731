<ng-container *ngIf="siteLayout$ | async as siteLayout">
  
  <div *ngIf="!siteLayout.isAdmin">
  <div class="border-top"></div>
  <div *ngIf="!siteLayout.isUser" class="flex justify-around pt-10 pb-20">
    <div class="flex flex-col">
      <a href="https://www.facebook.com/guardstreet/">Facebook</a
      ><a href="https://twitter.com/guardstreet">Twitter</a
      ><a href="https://www.linkedin.com/company/guard-street-partners-llc/"
        >LinkedIn</a
      >
    </div>
    <div class="flex flex-col">
      <a href="https://guardstreet.com/">Home</a
      ><a href="https://guardstreet.com/careers/">Careers</a
      ><a href="https://guardstreet.com/privacy-policy/">Privacy Policy</a>
    </div>
    <div class="flex flex-col">
      <a href="https://guardstreet.com/terms-and-conditions/">Terms of Use</a
      ><a href="https://guardstreet.com/faq/">FAQS</a
      ><a href="https://guardstreet.com/affliliates/">Affiliates</a>
    </div>
    <div class="flex flex-col">
      <a href="https://guardstreet.com/cyber-scan-set-up/">Cyber Scan Setup</a
      ><a href="https://guardstreetcyberpro.sikichlabs.com/"
        >My Business Portal</a
      ><a href="https://guardstreet.com/contact-us/">Contact Us</a>
    </div>
  </div>
  <div *ngIf="siteLayout.isUser" class="flex flex-row user-footer gap-40">
    <div class="flex flex-col">
      <img
        alt="Legal Club Logo"
        class=""
        style="width: 23%;"
        src="/assets/img/logo-legalclub.jpg"
      />
      <p>Corporate Headquarters</p>
      <p>7771 W. Oakland Park Blvd., Suite 217</p>
      <p>Sunrise, Florida 33351-6796</p>
      <p>Toll Free Main 800-316-5387
      <p>Local Main 954-377-0222</p>
      <p>Fax 954-377-0245</p>
      <p>info&#64;legalclub.com</p>
    </div>
    <p>
      Legal Club provides meaningful benefits for everyday life including legal,
      tax, identity theft solutions, financial education and life events
      counseling to over 2,000,000 working Americans and their families. Legal
      Club’s suite of products can be tailored to meet the needs of evolving
      employee populations and are designed to provide ultimate flexibility for
      employee benefits brokers and HR leaders. For more than two decades, Legal
      Club has been the proven choice for organizations around the country.
    </p>
  </div>
</div>

</ng-container>
