import { Member } from "../models/member.model";

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MembersActions {
  const typeName = '[Member]';

  export class GetMembers {
    public static readonly type = `${typeName} Get All`;    
  }

  export class GetSelectedMember {
    public static readonly type = `${typeName} Get Selected`;
    constructor(public id: number) {}
  }

  export class EditMember {
    public static readonly type = `${typeName} Edit Selected`;
    constructor(public id: number) {}
  }

  export class DeleteMember {
    public static readonly type = `${typeName} Delete`;
    constructor(public id: number) {}
  }

  export class UpdateMember {
    public static readonly type = `${typeName} Update`;    
  }

  export class CreateNewMember {
    public static readonly type = `${typeName} Create New`;    
  }

  export class SearchMember {
    public static readonly type= `${typeName} search`;  
    constructor(public search: string) {}  
  }

  export class ClearSearch {
    public static readonly type = `${typeName} Clear Search`;
  }

  export class LoginMember {
    public static readonly type= `${typeName} login`;  
    constructor(public member: Member) {}  
  }

  export class LogoutMember {
    public static readonly type= `${typeName} logout`;  
  }

}
