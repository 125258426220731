import { Member } from "./member.model";

export class Client {
  id!:number;
  name!:string;
  apiKey?:string;
  slug?:string;
  active?:boolean;
  memberDTOs?: Array<Member>;
  wholesaleEmail?: string;
  createdAt!: string;
  updatedAt!: string;
}