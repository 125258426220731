<ng-container *ngIf="siteLayout$ | async as siteLayout">
  <div *ngIf="!siteLayout.isAdmin" class="flex items-center justify-between header-container" [ngClass]="{'header-shadow': !siteLayout.isUser, 'border-bottom':  siteLayout.isUser}">
    <img [src]="showImage(siteLayout)" style="padding-left: 1rem;" [ngClass]="{'main-header-logo': !siteLayout.isUser, 'legalclub-logo':siteLayout.isUser}" />
    <div style="padding-right: 1rem;" *ngIf="!siteLayout.isUser">
      <button mat-button (click)="login()">
        Log In
      </button>
    </div>
    <div class="flex gap-10" style="padding-right: 1rem;" *ngIf="siteLayout.isUser">
      <p>{{member?.firstName}} {{member?.lastName}} {{member?.email}}</p>
      <button mat-button (click)="logout()">
        Logout
      </button>
    </div>
  </div>
</ng-container>
