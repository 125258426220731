import { Client } from "./client.model";

export class User {
  id!:number;
  email!:string;
  createdAt?:string;
  updatedAt?:string;
  superAdmin?:boolean;
  client?: Client;
}
