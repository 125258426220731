<div class="flex flex-col gap-2 max-w-50 pt-9">
  <button mat-button type="button" (click)="logout()">Logout</button>

  <div class="nav-link"><a href="/admin/clients">Clients</a></div>
  <div class="nav-link"><a href="/admin/members">Members</a></div>
  <div class="nav-link"><a href="/admin/users">Users</a></div>  
  <div class="nav-link"><a href="/admin/benefits">Benefits</a></div>  
  <form [formGroup]="clientForm" class="flex flex-col">
    <mat-label>Select a Client to View Active Member Report</mat-label>
    <mat-form-field class="nav-select">
      <mat-select class="nav-dropdown" formControlName="clientId" (selectionChange)="viewReport()">        
        <mat-option *ngFor="let client of clients" [value]="client.id">
          {{ client.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</div>
