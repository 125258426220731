<guardstreet-site-header [member]="member$ | async"></guardstreet-site-header>
<ng-container *ngIf="siteLayout$ | async as siteLayout">
  <section>
    <!--this is for the admin site-->
    <div class="admin-container" *ngIf="siteLayout.isAdmin">
      <div class="flex flex-row gap-10 admin-content">
        <guardstreet-admin-side-nav [clients]="clients$ | async"></guardstreet-admin-side-nav>
        <div class="admin-detail-container">
        <router-outlet></router-outlet>
      </div>
      </div>
    </div>
    <!--this is for the login-register-password-->
    <div *ngIf="!siteLayout.isAdmin">
      <router-outlet></router-outlet>
    </div>
  </section>
</ng-container>
<guardstreet-site-footer></guardstreet-site-footer>
