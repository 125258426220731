import { Component, Input } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { SiteLayoutViewModel } from '../site-layout/site-layout.view-model';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Member, MembersActions } from '@guardstreet/data-access-shared';
import { Store } from '@ngxs/store';

@Component({
  selector: 'guardstreet-site-header',
  templateUrl: './site-header.component.html',
  styleUrl: './site-header.component.scss',
})
export class SiteHeaderComponent  {
  siteLayout$: Observable<SiteLayoutViewModel>;
  @Input() member!: Member | null

  constructor(private router: Router, private store: Store) {
    this.siteLayout$ = router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map((e) => {
        const url = new URL(e.url, location.href);
        const model = {
          isMain: url.pathname === '/' || url.pathname.endsWith('login'),
          isUser: url.pathname === '/benefits',
          isAdmin: url.pathname.includes('admin'),
        } as SiteLayoutViewModel;

        return model;
      })
    );
  }

  showImage(siteLayout: SiteLayoutViewModel) {
    return siteLayout.isUser
      ? '/assets/img/logo-legalclub.jpg'
      : '/assets/img/logo-main.png';
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    /*
    We clear the storage on all tabs by setting a new item, if we depend on the clear to trigger
    it can cause an infinite loop of calls accross tabs/browsers
    */
    localStorage.setItem('hor-logout', Date.now().toString());
    localStorage.removeItem('hor-logout');
    this.store.dispatch(new MembersActions.LogoutMember());
    this.router.navigate(['/login']);
  }

  login() {    
    this.router.navigate(['/login']);
  }
}
