import { Component } from '@angular/core';
import { SiteLayoutViewModel } from '../site-layout/site-layout.view-model';
import { Observable } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { filter, map } from "rxjs/operators";
@Component({
  selector: 'guardstreet-site-footer',
  templateUrl: './site-footer.component.html',
  styleUrl: './site-footer.component.scss'
})
export class SiteFooterComponent {
  siteLayout$: Observable<SiteLayoutViewModel>;

  constructor(
    private router: Router
  ) {

    this.siteLayout$ = router.events.pipe(
      filter((e): e is NavigationEnd => e instanceof NavigationEnd),
      map(e => {        
        const url = new URL(e.url, location.href);
        const model = {
          isMain: url.pathname === '/',    
          isUser: url.pathname ==='/benefits',
          isAdmin: url.pathname.includes('admin')
        } as SiteLayoutViewModel;

        return model;
      })
    );
  }


}

